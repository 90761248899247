import React, { useEffect, useState } from 'react';
import AOS from "aos";
import 'swiper/css';
import OffCanvasTuning from "../components/OffCanvasTuning";
import { useTranslation } from "react-i18next";
import { getServiceFAQs, getServices } from "../api/axios";
import CarBrandsReference from '../components/CarBrandsReference';
import PartnersReference from '../components/PartnersReference';
import ScrollNav from '../components/ScrollNav';
import { LoadingProductGrid, LoadingSection, LoadingText } from '../components/LoadingPlaceholder';

const Services = () => {
  const { t, i18n } = useTranslation();
  const [services, setServices] = useState([]);
  const [serviceFaqs, setServiceFaqs] = useState([]);
  const [loading, setLoading] = useState(true);

  const sections = [
    { id: 'intro', label: 'services' },
    { id: 'services', label: 'our-services' },
    { id: 'faq', label: 'faq' },
    { id: 'references', label: 'references' }
  ];

  useEffect(() => {
    document.title = 'Services | Asferrari ChipTuning';
    AOS.init({
      delay: 100,
      duration: 1000,
    });
    AOS.refresh();

    Promise.all([
      getServices(),
      getServiceFAQs()
    ])
      .then(([servicesRes, faqsRes]) => {
        setServices(servicesRes?.data?.data || []);
        setServiceFaqs(faqsRes?.data?.data || []);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error loading services data:', error);
        setLoading(false);
      });
  }, []);

  function onRoadLinkClick(e) {
    document.querySelectorAll(".road__list-link").forEach((value, key) => {
      value.classList.remove("active");
    })
    e.target.classList.add("active")
  }

  return (
    <>
      <OffCanvasTuning />
      <ScrollNav sections={sections} />

      <main>
        <section id="intro" className="intro">
          <img src="/assets/images/services-bg.png" alt="background" className="intro__background" />
          <div data-aos="fade-right" className="container h-100">
            <div className="intro__content">
              <h1 className="intro__headline">{t('services')}</h1>
              <p className="intro__description">{t('services-welcome')}</p>
              <a href="#services" className="intro__link">
                <span>{t('read-more')}</span>
                <div className="intro__link-icon">
                  <img src="/assets/icons/right.svg" alt="right icon" />
                </div>
              </a>
            </div>
          </div>
        </section>

        <section id="services" className="services">
          <div className="container">
            {loading ? (
              <LoadingProductGrid count={6} />
            ) : (
              <div className="services__wrapper row g-4">
                {services.map((value, index) => (
                  <div data-aos="fade-left" className="col-12 col-md-6 col-lg-4" key={index}>
                    <div className="card__primary">
                      <div className="card__primary-img">
                        <img className="service-image" src={"data:image/png;base64, " + value?.servicePicture} alt={value?.title} />
                      </div>
                      <div className="d-flex align-items-center gap-2 justify-content-between">
                        <span className="card__primary-title">{value?.title}</span>
                      </div>
                      <p className="card__primary-long-description">{value?.text}</p>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </section>

        <section id="faq" data-aos="fade-right" className="faq">
          <div className="container">
            <h2 className="faq__headline">{t('faq')}</h2>
            <div className="faq__wrapper">
              {loading ? (
                Array(5).fill(0).map((_, index) => (
                  <LoadingText key={index} height="60px" margin="0 0 10px 0" />
                ))
              ) : (
                <div className="accordion accordion-flush">
                  {serviceFaqs.map((value, index) => (
                    <div key={index} className="accordion-item">
                      <h2 className="accordion-header">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                          data-bs-target={`#accordion${value?.id}`} aria-expanded="false" aria-controls={`accordion${value?.id}`}>
                          {value?.title}
                        </button>
                      </h2>
                      <div id={`accordion${value?.id}`} className="accordion-collapse collapse" data-bs-parent={`#accordion${value?.id}`}>
                        <div className="accordion-body">
                          {value?.text}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </section>

        <section id="references">
          <CarBrandsReference />
          <PartnersReference />
        </section>
      </main>
    </>
  )
}
export default Services