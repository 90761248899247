import React, { useEffect, useState } from 'react';
import AOS from "aos";
import "aos/dist/aos.css";
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from "swiper/modules";
import OffCanvasTuning from "../components/OffCanvasTuning";
import { getMakes, getModelsByBrandName, getProducts, getTuningByAllData, getYearsByBrandName, getModelsByMake } from "../api/axios";
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import CarBrandsReference from '../components/CarBrandsReference';
import PartnersReference from '../components/PartnersReference';
import ScrollNav from '../components/ScrollNav';
import { LoadingProductGrid, LoadingSection, LoadingText } from '../components/LoadingPlaceholder';

const Home = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);
  const [loading, setLoading] = useState(true);
  const [brands, setBrands] = useState([]);
  const [products, setProducts] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState([]);
  const [brandName, setBrandName] = useState("");
  const [selectedModel, setSelectedModel] = useState({});
  const [modelName, setModelName] = useState("");
  const [motorOptions, setEngineOptions] = useState([]);
  const [engineName, setEngineName] = useState("");
  const [yearOptions, setYearOptions] = useState([]);
  const [year, setYear] = useState("");
  const [tuningData, setTuningData] = useState("");

  const sections = [
    { id: 'intro', label: 'home' },
    { id: 'reasons', label: 'why-us' },
    { id: 'store', label: 'our-store' },
    { id: 'founder', label: 'founder' },
    { id: 'references', label: 'references' }
  ];

  useEffect(() => {
    document.title = 'Asferrari ChipTuning';
    AOS.init({
      delay: 100,
      duration: 1000,
    });
    AOS.refresh();
    updateCounters();

    setBrandName(t('select-car'))
    setModelName(t('model'))
    setEngineName(t('engine'))
    setYear(t('year'))

    getMakes()
      .then(value => setBrands(value?.data))

    getProducts()
      .then(value => {
        setProducts(value?.data?.data)
        setLoading(false)
      })
  }, []);

  function getYearsByBrandAndModel(model) {
    if (brandName === t('select-car') || !brandName) return;
    
    setModelName(model);
    getYearsByBrandName(brandName, model)
      .then(value => {
        if (value?.data) {
          setYearOptions(value.data);
        }
      })
      .catch(error => {
        console.error('Error fetching years:', error);
        setYearOptions([]);
      });
  }

  function getModelsByMakeName(make) {
    setBrandName(make);
    getModelsByMake(make)
      .then(value => {
        if (value?.data) {
          setSelectedBrand(value.data);
        }
      })
      .catch(error => {
        console.error('Error fetching models:', error);
        setSelectedBrand([]);
      });
    
    // Reset dependent fields
    setModelName(t('model'));
    setYear(t('year'));
    setEngineName(t('engine'));
    setYearOptions([]);
    setEngineOptions([]);
  }

  function getTuning(engine) {
    if (!brandName || !modelName || !year) return;
    
    setEngineName(engine);
    getTuningByAllData(brandName, modelName, year, engine)
      .then(value => {
        if (value?.data) {
          setTuningData(value.data);
        }
      })
      .catch(error => {
        console.error('Error fetching tuning data:', error);
      });
  }

  function getMotorsByBrandAndModelAndYear(selectedYear) {
    if (!brandName || !modelName) return;
    
    setYear(selectedYear);
    getModelsByBrandName(brandName, modelName, selectedYear)
      .then(value => {
        if (value?.data) {
          setEngineOptions(value.data);
        }
      })
      .catch(error => {
        console.error('Error fetching engines:', error);
        setEngineOptions([]);
      });
    
    // Reset engine
    setEngineName(t('engine'));
  }

  function goToTuning(e) {
    if (brandName && modelName && year && engineName && 
        brandName !== t('select-car') && 
        modelName !== t('model') && 
        year !== t('year') && 
        engineName !== t('engine')) {
      window.location.href = `/tuning/${encodeURIComponent(brandName)}/${encodeURIComponent(modelName)}/${encodeURIComponent(year)}/${encodeURIComponent(engineName)}`;
    }
  }

  function updateCounters() {
    const myNum = document.querySelectorAll(".count");
    const speed = 150;

    myNum.forEach((myCount) => {
      let target_count = myCount.dataset.count;
      target_count = parseInt(target_count);
      let init_count = 0;

      const updateNumber = () => {
        init_count += Math.ceil(target_count / speed);
        if (init_count > target_count) {
          init_count = target_count;
        }

        myCount.innerText = `${init_count}`;

        if (init_count < target_count) {
          requestAnimationFrame(updateNumber);
        }
      };

      updateNumber();
    });
  }

  return (
    <>
      <OffCanvasTuning brands={brands} />
      <ScrollNav sections={sections} />

      <main style={{ position: 'relative' }}>
        <section id="intro" className="intro" style={{ position: 'relative', zIndex: 10 }}>
          <img src="/assets/images/home-car.gif" alt="background" className="intro__background" />
          <div data-aos="fade-left" className="container h-100">
            <div className="store__box">
              <a href="https://apps.apple.com/hr/app/asferrari/id6504839393">
                <img src="/assets/images/apple-store-logo.png" className='img-fluid' alt="AsFerrari apple store" />
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.asferrari.app">
                <img src="/assets/images/google-play-badge-logo.png" className='img-fluid' alt="AsFerrari google play" />
              </a>
            </div>
            <div className="intro__content">
              <h1 className="intro__headline">{t('where-speed-meets')}</h1>
              <p className="intro__description">{t('where-speed-meets-sub')}</p>
              <div className="filter__wrapper" style={{ position: 'relative', zIndex: 99999 }}>
                <div className="dropdown" style={{ position: 'relative', zIndex: 99999 }}>
                  <button className="select__btn" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <span data-text="selectCar" className="select__text">
                      {brandName}
                    </span>
                    <img src="/assets/icons/bottom.svg" alt="bottom icon" />
                  </button>
                  <ul className="dropdown-menu select__menu" style={{ position: 'absolute', zIndex: 99999 }}>
                    {brands.map((brand, index) => (
                      <li key={index}>
                        <button
                          className="dropdown-item select__menu-item"
                          type="button"
                          onClick={() => getModelsByMakeName(brand)}
                        >
                          {brand}
                          <img src="/assets/icons/right.svg" width="8" height="16" alt="right icon" />
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="dropdown" style={{ position: 'relative', zIndex: 99998 }}>
                  <button className="select__btn" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <span data-text="selectCar" className="select__text">
                      {modelName}
                    </span>
                    <img src="/assets/icons/bottom.svg" alt="bottom icon" />
                  </button>
                  <ul className="dropdown-menu select__menu" style={{ position: 'absolute', zIndex: 99999 }}>
                    {selectedBrand.map((model, index) => (
                      <li key={index}>
                        <button
                          className="dropdown-item select__menu-item"
                          type="button"
                          onClick={() => getYearsByBrandAndModel(model)}
                        >
                          {model}
                          <img src="/assets/icons/right.svg" width="8" height="16" alt="right icon" />
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="dropdown" style={{ position: 'relative', zIndex: 99997 }}>
                  <button className="select__btn" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <span data-text="selectCar" className="select__text">
                      {year}
                    </span>
                    <img src="/assets/icons/bottom.svg" alt="bottom icon" />
                  </button>
                  <ul className="dropdown-menu select__menu" style={{ position: 'absolute', zIndex: 99999 }}>
                    {yearOptions.map((year, index) => (
                      <li key={index}>
                        <button
                          className="dropdown-item select__menu-item"
                          type="button"
                          onClick={() => getMotorsByBrandAndModelAndYear(year)}
                        >
                          {year}
                          <img src="/assets/icons/right.svg" width="8" height="16" alt="right icon" />
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="dropdown" style={{ position: 'relative', zIndex: 99996 }}>
                  <button className="select__btn" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <span data-text="selectCar" className="select__text">
                      {engineName}
                    </span>
                    <img src="/assets/icons/bottom.svg" alt="bottom icon" />
                  </button>
                  <ul className="dropdown-menu select__menu" style={{ position: 'absolute', zIndex: 99999 }}>
                    {motorOptions.map((engine, index) => (
                      <li key={index}>
                        <button
                          className="dropdown-item select__menu-item"
                          type="button"
                          onClick={() => getTuning(engine)}
                        >
                          {engine}
                          <img src="/assets/icons/right.svg" width="8" height="16" alt="right icon" />
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
                <button 
                  className="btn btn-danger"
                  onClick={goToTuning}
                  disabled={!brandName || !modelName || !year || !engineName}
                  style={{ position: 'relative', zIndex: 99995 }}
                >
                  {t('lets-tune-your-car')}
                  <img src="/assets/icons/right.svg" alt="right" />
                </button>
              </div>
              <a href="#reasons" className="intro__link">
                <span>{t('read-more')}</span>
                <div className="intro__link-icon">
                  <img src="/assets/icons/right.svg" alt="right icon" />
                </div>
              </a>
            </div>
            <div className="intro__km">
              <img className="intro__km-icon" width="12" height="22" src="/assets/icons/lightning.svg" alt="lightning icon" />
              <span className="intro__km-count count" data-count="360">0</span>
              <span className="intro__km-name">{t('kilometer')}</span>
            </div>
          </div>
        </section>

        <section id="reasons" className="reasons" style={{ position: 'relative', zIndex: 1 }}>
          <div data-aos="fade-left" className="container">
            <h2 className="reasons__headline">
              <span className="fw-bold">{t('6-reason')}</span> <br /> {t('choose-asfferari')}
            </h2>

            <div className="reasons__content">
              <div className="swiper reasonSwiper">
                <Swiper
                  spaceBetween={30}
                  slidesPerView={1}
                  modules={[Navigation]}
                  navigation={{
                    prevEl: navigationPrevRef.current,
                    nextEl: navigationNextRef.current,
                  }}
                  breakpoints={{
                    578: {
                      width: 578,
                      slidesPerView: 2,
                    },
                    768: {
                      width: 768,
                      slidesPerView: 3,
                    },
                    1024: {
                      width: 1024,
                      slidesPerView: 4,
                    },
                  }}
                >
                  <div className="swiper-wrapper reasons__wrapper">
                    <SwiperSlide>
                      <div className="swiper-slide">
                        <div className="reasons__item">
                          <div className="reasons__item-icon">
                            <img src="/assets/icons/winning.svg" alt="Chip Tuning Market Leader" />
                          </div>
                          <span className="reasons__item-headline">{t('chip-tuning-leader')}</span>
                          <p className="reasons__item-tag">{t('chip-tuning-leader-sub')}</p>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="swiper-slide">
                        <div className="reasons__item">
                          <div className="reasons__item-icon">
                            <img src="/assets/icons/speedmeter.svg" alt="speedmeter icon" />
                          </div>
                          <span className="reasons__item-headline">{t('horse-torque')}</span>
                          <p className="reasons__item-tag">{t('horse-torque-sub')}</p>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="swiper-slide">
                        <div className="reasons__item">
                          <div className="reasons__item-icon">
                            <img src="/assets/icons/oil-spill.svg" alt="oil-spill icon" />
                          </div>
                          <span className="reasons__item-headline">{t('enhanced-fuel')}</span>
                          <p className="reasons__item-tag">{t('enhanced-fuel-sub')}</p>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="swiper-slide">
                        <div className="reasons__item">
                          <div className="reasons__item-icon">
                            <img src="/assets/icons/speedometer-2.svg" alt="speedometer icon" />
                          </div>
                          <span className="reasons__item-headline">{t('throttle')}</span>
                          <p className="reasons__item-tag">{t('throttle-sub')}</p>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="swiper-slide">
                        <div className="reasons__item">
                          <div className="reasons__item-icon">
                            <img src="/assets/icons/cpu.svg" alt="Latest software system icon" />
                          </div>
                          <span className="reasons__item-headline">{t('latest-software')}</span>
                          <p className="reasons__item-tag">{t('latest-software-sub')}</p>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="swiper-slide">
                        <div className="reasons__item">
                          <div className="reasons__item-icon">
                            <img src="/assets/icons/shield.svg" alt="shield icon" />
                          </div>
                          <span className="reasons__item-headline">{t('warranty')}</span>
                          <p className="reasons__item-tag">{t('warranty-sub')}</p>
                        </div>
                      </div>
                    </SwiperSlide>
                  </div>
                </Swiper>
              </div>
              <div ref={navigationNextRef} className="reasons__next">
                <img src="/assets/icons/right.svg" alt="next" />
              </div>
              <div ref={navigationPrevRef} className="reasons__prev">
                <img src="/assets/icons/left.svg" alt="prev" />
              </div>
            </div>
          </div>
        </section>

        <section id="store" data-aos="fade-right" className="store">
          <div className="container">
            <div className="d-block d-md-flex align-items-end gap-2 justify-content-between">
              <h3 className="store__headline">
                <span>{t('check-our-store')}</span>
                {t('join-club-today')}
              </h3>
              <a href="/store" className="store__link">
                <span>{t('more-equipment')}</span>
                <img src="/assets/icons/right.svg" alt="right" />
              </a>
            </div>

            {loading ? (
              <LoadingProductGrid count={3} />
            ) : (
              <div className="store__wrapper row g-4">
                {products?.slice(0, 3).map((value, index) => (
                  <div key={index} className="col-12 col-md-6 col-lg-4">
                    <div className="card__primary">
                      <div className="card__primary-img">
                        <img src={"data:image/png;base64, " + value?.image} alt={value?.title} />
                      </div>
                      <div className="d-flex align-items-center gap-2 justify-content-between">
                        <span className="card__primary-title">{value?.title}</span>
                        <span className="card__primary-price">{value?.price}</span>
                      </div>
                      <p className="card__primary-description">{value?.description}</p>
                      <a href={value?.link} className="btn btn-dark card__primary-btn">
                        {t("buy-now")}
                        <img src="/assets/icons/right.svg" height="16" alt="right" />
                      </a>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </section>

        <section id="founder" data-aos="fade-left" className="founder">
          <div className="container">
            <div className="founder__wrapper">
              <div className="founder__about">
                <h4 className="founder__headline">{t('word-founder')}</h4>
                <p className="founder__description">
                  {t('word-founder-1')}
                  <br />
                  {t('word-founder-2')}
                </p>
                <div className="founder__signature">
                  <img width="250" height="105" src="/assets/images/durovic-signature.png" alt="signature" />
                </div>
                <span className="founder__title">Đorđije Đurović - CEO Asferrari</span>
              </div>
              <div className="founder__img">
                <img src="/assets/images/founder.png" alt="founder picture" />
              </div>
            </div>
          </div>
        </section>

        <section id="references">
          <CarBrandsReference />
          <PartnersReference />
        </section>
      </main>
    </>
  )
}

export default Home