import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const NavContainer = styled.div`
  position: fixed;
  right: 40px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1000;
  padding: 15px;
  border-radius: 30px;

  @media (max-width: 768px) {
    right: 20px;
    padding: 12px;
  }

  @media (max-width: 480px) {
    padding: 8px 12px;
  }
`;

const NavList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const NavItem = styled.li`
  margin: 20px 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media (max-width: 768px) {
    margin: 15px 0;
  }
`;

const NavLabel = styled.span`
  opacity: 0;
  transform: translateX(20px);
  transition: all 0.3s ease;
  margin-right: 15px;
  color: #ffffff;
  font-weight: 600;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  text-shadow: 0 2px 4px rgba(0,0,0,0.4);
  padding: 5px 10px;
  border-radius: 15px;

  ${NavItem}:hover &,
  ${NavItem}.active & {
    opacity: 1;
    transform: translateX(0);
  }

  @media (max-width: 768px) {
    font-size: 14px;
    margin-right: 12px;
  }

  @media (max-width: 480px) {
    font-size: 12px;
    margin-right: 10px;
  }
`;

const NavLink = styled.a`
  display: block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  background: transparent;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  box-shadow: 0 2px 4px rgba(0,0,0,0.4);

  &:hover,
  &.active {
    background: #ffffff;
    transform: scale(1.3);
  }

  @media (max-width: 768px) {
    width: 10px;
    height: 10px;
  }
`;

const ScrollMouse = styled.div`
  position: fixed;
  right: 30px;
  bottom: 20px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 15px;

  @media (max-width: 768px) {
    right: 15px;
    padding: 8px 12px;
  }

  @media (max-height: 500px) {
    display: none;
  }
`;

const ScrollText = styled.span`
  margin-bottom: 5px;
  font-size: 12px;
  color: #ffffff;
  opacity: 0.9;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-shadow: 0 1px 3px rgba(0,0,0,0.3);
`;

const Mouse = styled.div`
  width: 20px;
  height: 32px;
  border: 2px solid #ffffff;
  border-radius: 10px;
  position: relative;
  box-shadow: 0 1px 3px rgba(0,0,0,0.3);
`;

const Wheel = styled.div`
  width: 3px;
  height: 6px;
  background: #ffffff;
  position: absolute;
  top: 6px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 2px;
  animation: scroll 1.5s infinite;

  @keyframes scroll {
    0% {
      transform: translate(-50%, 0);
      opacity: 1;
    }
    100% {
      transform: translate(-50%, 15px);
      opacity: 0;
    }
  }
`;

const ScrollNav = ({ sections = [
  { id: 'intro', label: 'Home' },
  { id: 'blog', label: 'Blog' },
  { id: 'references', label: 'References' }
] }) => {
  const { t } = useTranslation();
  const [activeSection, setActiveSection] = useState(sections[0]?.id);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY + 100;

      for (const section of sections) {
        const element = document.getElementById(section.id);
        if (element) {
          const { offsetTop, offsetHeight } = element;
          if (scrollPosition >= offsetTop && scrollPosition < offsetTop + offsetHeight) {
            setActiveSection(section.id);
            break;
          }
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); 

    return () => window.removeEventListener('scroll', handleScroll);
  }, [sections]);

  const scrollToSection = (e, id) => {
    e.preventDefault();
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>
      <NavContainer>
        <NavList>
          {sections.map((section) => (
            <NavItem key={section.id} className={activeSection === section.id ? 'active' : ''}>
              <NavLabel>{t(section.label)}</NavLabel>
              <NavLink
                href={`#${section.id}`}
                className={activeSection === section.id ? 'active' : ''}
                onClick={(e) => scrollToSection(e, section.id)}
              />
            </NavItem>
          ))}
        </NavList>
      </NavContainer>

      <ScrollMouse>
        <ScrollText>{t('scroll')}</ScrollText>
        <Mouse>
          <Wheel />
        </Mouse>
      </ScrollMouse>
    </>
  );
};

export default ScrollNav; 