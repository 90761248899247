import React from 'react';
import styled, { keyframes } from 'styled-components';

const shimmer = keyframes`
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
`;

const LoadingContainer = styled.div`
  width: 100%;
  height: ${props => props.height || '100px'};
  margin: ${props => props.margin || '10px 0'};
  border-radius: ${props => props.radius || '4px'};
  background: linear-gradient(to right, #1a1a1a 8%, #2a2a2a 18%, #1a1a1a 33%);
  background-size: 2000px 100px;
  animation: ${shimmer} 1.5s linear infinite;
`;

const LoadingTextBase = styled(LoadingContainer)`
  width: ${props => props.width || '100%'};
  height: ${props => props.height || '20px'};
  opacity: 0.8;
`;

const LoadingImage = styled(LoadingContainer)`
  width: 100%;
  height: ${props => props.height || '300px'};
  border-radius: ${props => props.radius || '8px'};
  opacity: 0.9;
`;

const LoadingGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  padding: 20px;
`;

const LoadingCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 15px;
  border-radius: 8px;
  background: #1c1c1c;
  box-shadow: 0 4px 12px rgba(0,0,0,0.3);
  border: 1px solid rgba(255,255,255,0.05);
`;

export const LoadingProductCard = () => (
  <LoadingCard>
    <LoadingImage height="200px" />
    <LoadingTextBase height="24px" width="70%" />
    <LoadingTextBase height="16px" width="40%" />
    <LoadingTextBase height="40px" />
  </LoadingCard>
);

export const LoadingProductGrid = ({ count = 6 }) => (
  <LoadingGrid>
    {Array(count).fill(0).map((_, index) => (
      <LoadingProductCard key={index} />
    ))}
  </LoadingGrid>
);

export const LoadingSection = ({ height, margin, radius }) => (
  <LoadingContainer height={height} margin={margin} radius={radius} />
);

export const LoadingText = ({ width, height }) => (
  <LoadingTextBase width={width} height={height} />
);

export default {
  LoadingProductCard,
  LoadingProductGrid,
  LoadingSection,
  LoadingText
}; 