import React, { useEffect, useState } from 'react';
import AOS from "aos";
import 'swiper/css';
import OffCanvasTuning from "../components/OffCanvasTuning";
import { useTranslation } from "react-i18next";
import { getAcademyList } from "../api/axios";
import CarBrandsReference from '../components/CarBrandsReference';
import PartnersReference from '../components/PartnersReference';
import ScrollNav from '../components/ScrollNav';
import { LoadingProductGrid } from '../components/LoadingPlaceholder';

const Academy = () => {
  const { t, i18n } = useTranslation();
  const [academy, setAcademy] = useState([]);
  const [loading, setLoading] = useState(true);

  const sections = [
    { id: 'intro', label: 'stay-updated' },
    { id: 'blog', label: 'today' },
    { id: 'references', label: 'references' }
  ];

  useEffect(() => {
    document.title = 'Academy | Asferrari ChipTuning';
    AOS.init({
      delay: 100,
      duration: 1000,
    });
    AOS.refresh();

    getAcademyList()
      .then(value => {
        setAcademy(value?.data?.data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error loading academy articles:', error);
        setLoading(false);
      });
  }, []);

  return (
    <>
      <OffCanvasTuning />
      <ScrollNav sections={sections} />

      <main>
        <section id="intro" className="intro">
          <img src={"/assets/images/academy-bg.png"} alt="background" className="intro__background" />
          <div data-aos="fade-right" className="container h-100">
            <div className="intro__content">
              <h1 className="intro__headline">{t('stay-updated')}</h1>
              <p className="intro__description">
                {t('follow-up')}
              </p>
              <a href="#blog" className="intro__link">
                <span>{t('read-more')}</span>
                <div className="intro__link-icon">
                  <img src={"/assets/icons/right.svg"} alt="right icon" />
                </div>
              </a>
            </div>
          </div>
        </section>

        <section id="blog" className="blog">
          <div data-aos="fade-left" className="container">
            <h2 className="title__center">
              {t('today')}<br />
              <span>{t('newspaper')}</span>
            </h2>
            {loading ? (
              <LoadingProductGrid count={6} />
            ) : (
              <div className="store__wrapper row g-4">
                {academy?.map((value, index) => {
                  const slug = value?.title
                    .toLowerCase()
                    .replace(/[^a-z0-9\s-]/g, '')
                    .replace(/\s+/g, '-')
                    .replace(/-+/g, '-')
                    .trim()
                    .replace(/^-+|-+$/g, '');
                  
                  return (
                    <div className="col-12 col-md-6 col-lg-4" key={index}>
                      <a href={`/academy/${encodeURIComponent(slug)}`}>
                        <div className="card__primary">
                          <div className="card__primary-img">
                            <img src={"data:image/png;base64, " + value?.academyPicture} alt={value?.title} />
                          </div>
                          <div className="d-flex align-items-center gap-2 justify-content-between">
                            <span className="card__primary-title w-100">{value?.title}</span>
                          </div>
                          <p className="card__primary-description">{value?.text?.substring(0, 100)}...</p>
                          <button type="button" className="btn btn-dark card__primary-btn">
                            {t('read-now')}
                            <img src="/assets/icons/right.svg" height="16" alt="right" />
                          </button>
                        </div>
                      </a>
                    </div>
                  )
                })}
              </div>
            )}
          </div>
        </section>

        <section id="references">
          <CarBrandsReference />
          <PartnersReference />
        </section>
      </main>
    </>
  )
}
export default Academy