"use client";
import React, { useEffect, useState } from 'react';
import AOS from "aos";
import 'swiper/css';
import OffCanvasTuning from "../components/OffCanvasTuning";
import { useTranslation } from "react-i18next";
import {
  APIProvider,
  Map,
  AdvancedMarker,
  InfoWindow,
  Pin
} from "@vis.gl/react-google-maps";
import { getDealerList } from "../api/axios";
import ScrollNav from '../components/ScrollNav';
import { LoadingSection } from '../components/LoadingPlaceholder';

const Dealers = () => {
  const { t, i18n } = useTranslation();
  const [markers, setMarkers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activeMarker, setActiveMarker] = useState(null);

  const mapStyle = [
    { elementType: "geometry", stylers: [{ color: "#242f3e" }] },
    { elementType: "labels.text.stroke", stylers: [{ color: "#242f3e" }] },
    { elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
    {
      featureType: "administrative.locality",
      elementType: "labels.text.fill",
      stylers: [{ color: "#d59563" }],
    },
    {
      featureType: "poi",
      elementType: "labels.text.fill",
      stylers: [{ color: "#d59563" }],
    },
    {
      featureType: "poi.park",
      elementType: "geometry",
      stylers: [{ color: "#263c3f" }],
    },
    {
      featureType: "poi.park",
      elementType: "labels.text.fill",
      stylers: [{ color: "#6b9a76" }],
    },
    {
      featureType: "road",
      elementType: "geometry",
      stylers: [{ color: "#38414e" }],
    },
    {
      featureType: "road",
      elementType: "geometry.stroke",
      stylers: [{ color: "#212a37" }],
    },
    {
      featureType: "road",
      elementType: "labels.text.fill",
      stylers: [{ color: "#9ca5b3" }],
    },
    {
      featureType: "road.highway",
      elementType: "geometry",
      stylers: [{ color: "#746855" }],
    },
    {
      featureType: "road.highway",
      elementType: "geometry.stroke",
      stylers: [{ color: "#1f2835" }],
    },
    {
      featureType: "road.highway",
      elementType: "labels.text.fill",
      stylers: [{ color: "#f3d19c" }],
    },
    {
      featureType: "transit",
      elementType: "geometry",
      stylers: [{ color: "#2f3948" }],
    },
    {
      featureType: "transit.station",
      elementType: "labels.text.fill",
      stylers: [{ color: "#d59563" }],
    },
    {
      featureType: "water",
      elementType: "geometry",
      stylers: [{ color: "#17263c" }],
    },
    {
      featureType: "water",
      elementType: "labels.text.fill",
      stylers: [{ color: "#515c6d" }],
    },
    {
      featureType: "water",
      elementType: "labels.text.stroke",
      stylers: [{ color: "#17263c" }],
    },
  ];

  const position = { lat: 42.560604004266494, lng: 19.100116524226724 };

  const sections = [
    { id: 'intro', label: 'dealers' },
    { id: 'map', label: 'dealer-map' }
  ];

  useEffect(() => {
    document.title = 'Dealers | Asferrari ChipTuning';
    AOS.init({
      delay: 100,
      duration: 1000,
    });
    AOS.refresh();

    getDealerList()
      .then(value => {
        const dealerData = value?.data?.data || [];
        console.log('Dealer data:', dealerData); // Debug için
        setMarkers(dealerData);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error loading dealers:', error);
        setLoading(false);
      });
  }, []);

  function onRoadLinkClick(e) {
    document.querySelectorAll(".road__list-link").forEach((value, key) => {
      value.classList.remove("active");
    })
    e.target.classList.add("active")
  }

  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  return (
    <>
      <OffCanvasTuning />
      <ScrollNav sections={sections} />

      <main>
        <section id="intro" className="intro">
          <img src={"/assets/images/academy-bg.png"} alt="background" className="intro__background" />
          <div data-aos="fade-right" className="container h-100">
            <div className="intro__content">
              <h1 className="intro__headline">{t('dealers')}</h1>
              <p className="intro__description">
                {t('dealers-map')}
              </p>
              <a href="#map" className="intro__link">
                <span>{t('read-more')}</span>
                <div className="intro__link-icon">
                  <img src={"/assets/icons/right.svg"} alt="right icon" />
                </div>
              </a>
            </div>
          </div>
        </section>

        <section id="map" className="blog">
          <div data-aos="fade-left" className="container">
            {loading ? (
              <LoadingSection height="600px" radius="12px" />
            ) : (
              <APIProvider apiKey='AIzaSyBonp6UkWccLe-qAT1Pm44f1JZzajWef68'>
                <div style={{ width: '100%', height: '600px', borderRadius: '12px', overflow: 'hidden' }}>
                  <Map
                    defaultCenter={position}
                    defaultZoom={3}
                    gestureHandling={'greedy'}
                    disableDefaultUI={true}
                    mapId={'fd6b96db192b1555'}
                    styles={mapStyle}
                  >
                    {markers.map(marker => {
                      const markerPosition = { 
                        lat: parseFloat(marker.lat), 
                        lng: parseFloat(marker.lng) 
                      };
                      
                      return (
                        <AdvancedMarker
                          key={marker.id}
                          position={markerPosition}
                          onClick={() => handleActiveMarker(marker.id)}
                        >
                          <Pin
                            background={'#FF0000'}
                            borderColor={'#FFFFFF'}
                            glyphColor={'#FFFFFF'}
                          />
                          {activeMarker === marker.id && (
                            <InfoWindow
                              position={markerPosition}
                              onCloseClick={() => setActiveMarker(null)}
                            >
                              <div style={{ padding: '8px' }}>
                                <h3 style={{ margin: '0 0 8px 0' }}>{marker.name}</h3>
                                <p style={{ margin: '0' }}>{marker.phone}</p>
                              </div>
                            </InfoWindow>
                          )}
                        </AdvancedMarker>
                      );
                    })}
                  </Map>
                </div>
              </APIProvider>
            )}
          </div>
        </section>
      </main>
    </>
  )
}
export default Dealers