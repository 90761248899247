import React, { useEffect, useState } from 'react';
import AOS from "aos";
import 'swiper/css';
import OffCanvasTuning from "../components/OffCanvasTuning";
import { useTranslation } from "react-i18next";
import { getAcademyList, getAcademyById } from "../api/axios";
import { useParams, useNavigate } from "react-router-dom";
import CarBrandsReference from '../components/CarBrandsReference';
import PartnersReference from '../components/PartnersReference';
import { LoadingSection, LoadingText } from '../components/LoadingPlaceholder';
import { Helmet } from 'react-helmet-async';

const AcademyDetail = () => {
  const { t, i18n } = useTranslation();
  const { slug } = useParams();
  const navigate = useNavigate();
  const [academyDetail, setAcademyDetail] = useState(null);
  const [loading, setLoading] = useState(true);
  const [metaDescription, setMetaDescription] = useState("");

  useEffect(() => {
    AOS.init({
      delay: 100,
      duration: 1000,
    });
    AOS.refresh();

    getAcademyList()
      .then(response => {
        const articles = response?.data?.data || [];
        const cleanSlug = decodeURIComponent(slug);
        
        const article = articles.find(article => {
          const articleSlug = article.title
            .toLowerCase()
            .replace(/[^a-z0-9\s-]/g, '')
            .replace(/\s+/g, '-')
            .replace(/-+/g, '-')
            .trim()
            .replace(/^-+|-+$/g, '');
          
          return articleSlug === cleanSlug;
        });

        if (article) {
          getAcademyById(article.id)
            .then(detailResponse => {
              const detailData = detailResponse?.data?.data;
              setAcademyDetail(detailData);
              
              // Create meta description from article text
              const tempDiv = document.createElement('div');
              tempDiv.innerHTML = detailData?.text || '';
              const textContent = tempDiv.textContent || tempDiv.innerText;
              setMetaDescription(textContent.substring(0, 160) + '...');
              
              setLoading(false);
            })
            .catch(error => {
              console.error('Error loading article details:', error);
              setLoading(false);
            });
        } else {
          navigate('/academy');
        }
      })
      .catch(error => {
        console.error('Error loading articles:', error);
        setLoading(false);
      });
  }, [slug, navigate]);

  const scrollToContent = () => {
    const contentSection = document.querySelector('.about');
    if (contentSection) {
      contentSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  // Format date if available
  const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return date.toLocaleDateString(i18n.language, {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  return (
    <>
      <Helmet>
        <title>{academyDetail?.title ? `${academyDetail.title} | Asferrari ChipTuning` : 'Academy Article | Asferrari ChipTuning'}</title>
        <meta name="description" content={metaDescription} />
        <meta property="og:title" content={academyDetail?.title} />
        <meta property="og:description" content={metaDescription} />
        <meta property="og:image" content={academyDetail?.academyPicture ? `data:image/png;base64, ${academyDetail.academyPicture}` : ''} />
        <meta property="og:type" content="article" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={academyDetail?.title} />
        <meta name="twitter:description" content={metaDescription} />
        <meta name="twitter:image" content={academyDetail?.academyPicture ? `data:image/png;base64, ${academyDetail.academyPicture}` : ''} />
        {academyDetail?.createdAt && <meta property="article:published_time" content={academyDetail.createdAt} />}
        {academyDetail?.updatedAt && <meta property="article:modified_time" content={academyDetail.updatedAt} />}
        <link rel="canonical" href={`${window.location.origin}/academy/${slug}`} />
      </Helmet>

      <OffCanvasTuning />

      <main>
        <section className="intro">
          {loading ? (
            <LoadingSection height="400px" />
          ) : (
            <>
              <img 
                src={`data:image/png;base64, ${academyDetail?.academyPicture}`} 
                alt={academyDetail?.title} 
                className="intro__background"
              />
              <button onClick={scrollToContent} className="scroll-button">
                <span className="scroll-text">{t('scroll-to-read')}</span>
                <div className="scroll-icon">
                  <div className="chevron"></div>
                  <div className="chevron"></div>
                  <div className="chevron"></div>
                </div>
              </button>
            </>
          )}
          <div data-aos="fade-right" className="container">
            <div className="intro__content">
              {loading ? (
                <LoadingText height="50px" width="80%" />
              ) : (
                <h1 className="intro__headline">{academyDetail?.title}</h1>
              )}
            </div>
          </div>
        </section>

        <section className="about">
          <div data-aos="fade-left" className="container">
            <div className="about__content">
              {loading ? (
                <>
                  <LoadingText height="40px" width="60%" margin="0 0 20px 0" />
                  <LoadingText height="300px" />
                </>
              ) : (
                <>
                  <div className="article-meta" style={{ marginBottom: '2rem', color: '#666' }}>
                    {academyDetail?.createdAt && (
                      <div className="article-date">
                        {t('published')}: {formatDate(academyDetail.createdAt)}
                      </div>
                    )}
                    {academyDetail?.category && (
                      <div className="article-category" style={{ marginTop: '0.5rem' }}>
                        {t('category')}: {academyDetail.category}
                      </div>
                    )}
                  </div>
                  <article className="article-content">
                    <div className="article-container">
                      <div 
                        className="about__description article-text"
                        dangerouslySetInnerHTML={{ __html: academyDetail?.text }}
                      />
                    </div>
                  </article>
                  
                  {academyDetail?.tags && (
                    <div className="article-tags">
                      {academyDetail.tags.split(',').map((tag, index) => (
                        <span key={index} className="tag">
                          #{tag.trim()}
                        </span>
                      ))}
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </section>
        <CarBrandsReference />
        <PartnersReference />
      </main>

      <style jsx>{`
        .article-content {
          max-width: 900px;
          margin: 0 auto;
          padding: 0 20px;
        }
        
        .article-container {
          background: rgba(255, 255, 255, 0.1);
          backdrop-filter: blur(10px);
          border-radius: 16px;
          padding: 2.5rem;
          box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.2);
          border: 1px solid rgba(255, 255, 255, 0.18);
          margin: 2rem 0;
        }
        
        .article-text {
          font-family: 'Arial', sans-serif;
          color: #e6e6e6;
          font-size: 1.1rem;
          line-height: 1.8;
          letter-spacing: 0.3px;
        }
        
        .article-text h2 {
          margin: 2rem 0 1rem;
          font-size: 1.8rem;
          color: #ffffff;
          font-weight: 600;
        }
        
        .article-text p {
          margin-bottom: 1.5rem;
        }
        
        .article-text img {
          max-width: 100%;
          height: auto;
          margin: 2rem 0;
          border-radius: 12px;
          box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
        }
        
        .article-text ul, .article-text ol {
          margin: 1.5rem 0;
          padding-left: 2rem;
          color: #e6e6e6;
        }
        
        .article-text li {
          margin-bottom: 0.8rem;
        }

        .article-text a {
          color: #ff3333;
          text-decoration: none;
          transition: color 0.3s ease;
        }

        .article-text a:hover {
          color: #ff6666;
        }
        
        .article-meta {
          text-align: center;
          margin-bottom: 2rem;
          color: #b3b3b3;
        }

        .article-date, .article-category {
          display: inline-block;
          margin: 0 1rem;
          font-size: 0.95rem;
        }

        .article-tags {
          display: flex;
          flex-wrap: wrap;
          gap: 0.5rem;
          margin-top: 2rem;
          justify-content: center;
        }

        .tag {
          background: rgba(255, 255, 255, 0.1);
          color: #e6e6e6;
          padding: 0.4rem 1rem;
          border-radius: 20px;
          font-size: 0.9rem;
          backdrop-filter: blur(5px);
          border: 1px solid rgba(255, 255, 255, 0.1);
          transition: all 0.3s ease;
        }

        .tag:hover {
          background: rgba(255, 255, 255, 0.2);
          transform: translateY(-2px);
        }
        
        @media (max-width: 768px) {
          .article-container {
            padding: 1.5rem;
          }
          
          .article-text {
            font-size: 1rem;
          }

          .article-text h2 {
            font-size: 1.5rem;
          }
        }

        .intro {
          position: relative;
          height: 100vh;
          overflow: hidden;
        }

        .intro__background {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
          z-index: 1;
        }

        .intro .container {
          position: absolute;
          bottom: 25%;
          left: 0;
          right: 0;
          z-index: 2;
        }

        .intro__content {
          position: relative;
          padding: 2rem;
          background: rgba(0, 0, 0, 0.75);
          backdrop-filter: blur(10px);
          border-radius: 16px;
          border: 1px solid rgba(255, 255, 255, 0.1);
          box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.3);
          max-width: 800px;
          width: 90%;
          margin: 0 auto;
          text-align: center;
        }

        .intro__headline {
          font-size: 2.5rem;
          line-height: 1.2;
          margin: 0;
          color: #ffffff;
          text-shadow: 0 2px 4px rgba(0,0,0,0.3);
        }

        .scroll-button {
          position: absolute;
          bottom: 10%;
          left: 50%;
          transform: translateX(-50%);
          background: transparent;
          border: none;
          cursor: pointer;
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 12px;
          z-index: 10;
          transition: all 0.3s ease;
          padding: 1rem;
        }

        .scroll-text {
          color: #fff;
          font-size: 1rem;
          text-transform: uppercase;
          letter-spacing: 1px;
          text-shadow: 0 2px 4px rgba(0,0,0,0.3);
          opacity: 0.9;
          font-weight: 500;
          background: rgba(0, 0, 0, 0.5);
          padding: 0.5rem 1rem;
          border-radius: 20px;
          backdrop-filter: blur(5px);
          transition: all 0.3s ease;
        }

        @media (max-width: 768px) {
          .intro .container {
            bottom: 30%;
          }

          .intro__content {
            padding: 1.5rem;
            width: 95%;
          }

          .intro__headline {
            font-size: 2rem;
          }
        }

        @media (max-width: 480px) {
          .intro .container {
            bottom: 35%;
          }

          .intro__content {
            padding: 1rem;
          }

          .intro__headline {
            font-size: 1.75rem;
          }
        }

        .scroll-button:hover .scroll-text {
          opacity: 1;
          transform: translateY(-2px);
          background: rgba(0, 0, 0, 0.7);
        }

        .scroll-icon {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 0;
        }

        .chevron {
          position: relative;
          width: 28px;
          height: 8px;
          opacity: 0;
          transform: scale(0.3);
          animation: move-chevron 3s ease-out infinite;
        }

        .chevron:first-child {
          animation: move-chevron 3s ease-out 1s infinite;
        }

        .chevron:nth-child(2) {
          animation: move-chevron 3s ease-out 2s infinite;
        }

        .chevron:before,
        .chevron:after {
          content: '';
          position: absolute;
          top: 0;
          height: 100%;
          width: 50%;
          background: #fff;
          box-shadow: 0 2px 4px rgba(0,0,0,0.3);
        }

        .chevron:before {
          left: 0;
          transform: skewY(30deg);
        }

        .chevron:after {
          right: 0;
          width: 50%;
          transform: skewY(-30deg);
        }

        @keyframes move-chevron {
          25% {
            opacity: 1;
            transform: translateY(9px) scale(0.3);
          }
          33% {
            opacity: 1;
            transform: translateY(16px) scale(0.3);
          }
          67% {
            opacity: 1;
            transform: translateY(16px) scale(0.3);
          }
          100% {
            opacity: 0;
            transform: translateY(24px) scale(0.3);
          }
        }
      `}</style>
    </>
  )
}

export default AcademyDetail