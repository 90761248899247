import React, { useEffect, useState } from 'react';
import AOS from "aos";
import 'swiper/css';
import OffCanvasTuning from "../components/OffCanvasTuning";
import { useTranslation } from "react-i18next";
import { getAbout } from "../api/axios";

const PrivacyPolicy = () => {
  const { t, i18n } = useTranslation();
  const [about, setAbout] = useState("");

  useEffect(() => {
    document.title = 'Privacy Policy | Asferrari ChipTuning';
    AOS.init({
      delay: 100,
      duration: 1000,
    });
    AOS.refresh();

    getAbout().then(value => setAbout(value?.data?.data))
  }, []);

  function onRoadLinkClick(e) {
    document.querySelectorAll(".road__list-link").forEach((value, key) => {
      value.classList.remove("active");
    })
    e.target.classList.add("active")
  }
  return (
    <>
      <OffCanvasTuning />

      <main>
        <section id="about" className="about">
          <div data-aos="fade-left" className="container">
            <div className="about__content">
              <h2 className="privacy__headline1">Privacy Policy AsFerarri Chiptuning</h2>
              <h2 className="privacy__headline2">1. Introduction</h2>
              <p className="privacy__description">AsFerrari Chiptuning  is committed to protecting and respecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website www.AsFerrari.com, use our services, or engage with us in any other manner. By using our website and services, you agree to the collection and use of information in accordance with this policy.</p>

              <h2 className="privacy__headline2">2. Information We Collect</h2>
              <p className="privacy__description">We may collect and process the following types of information:</p>

              <h5 className="privacy__headline3">2.1 Personal Information</h5>
              <p className="privacy__description">
                Contact Information: Name, email address, phone number, and mailing address.{"\n"}
                Account Information: Username and password if you create an account with us.{"\n"}
                Payment Information: Credit card details and billing information when you make a purchase.{"\n"}
                Vehicle Information: Details about your vehicle including make, model, year, and VIN.{"\n"}
              </p>
              <h5 className="privacy__headline3">2.2 Non-Personal Information</h5>
              <p className="privacy__description">
                Usage Data: Information on how you interact with our website, such as IP address, browser type, pages visited, and time spent on our site.{"\n"}
                Cookies: Small files stored on your device that collect information on your preferences and past actions on our website.{"\n"}
              </p>
              <h2 className="privacy__headline2">3. How We Use Your Information</h2>
              <p className="privacy__description">We use the information we collect for various purposes, including:{"\n"}
                To provide, operate, and maintain our services.{"\n"}
                To process and manage your orders and payments.{"\n"}
                To improve our website and services.{"\n"}
                To communicate with you, including sending updates and promotional materials.{"\n"}
                To personalize your experience on our website.{"\n"}
                To comply with legal obligations and protect our rights.{"\n"}
              </p>
              <h2 className="privacy__headline2">4. Sharing Your Information</h2>
              <p className="privacy__description">We may share your information in the following situations:{"\n"}{"\n"}
                With Service Providers: We may share your information with third-party service providers who perform services on our behalf, such as payment processing, data analysis, and email delivery.{"\n"}
                For Legal Reasons: We may disclose your information if required to do so by law or in response to valid requests by public authorities (e.g., a court or a government agency).{"\n"}
                Business Transfers: If we are involved in a merger, acquisition, or sale of assets, your information may be transferred as part of that transaction.{"\n"}
              </p>
              <h2 className="privacy__headline2">5. Security of Your Information</h2>
              <p className="privacy__description">We take reasonable measures to protect the information we collect from unauthorized access, use, or disclosure. However, no method of transmission over the internet or electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your personal information, we cannot guarantee its absolute security.</p>

              <h2 className="privacy__headline2">6. Your Rights and Choices</h2>
              <p className="privacy__description">
                You have certain rights regarding your personal information, including:{"\n"}{"\n"}
                Access: You have the right to request access to the personal information we hold about you.{"\n"}
                Correction: You have the right to request that we correct any inaccuracies in your personal information.{"\n"}
                Deletion: You have the right to request that we delete your personal information under certain conditions.{"\n"}
                Opt-Out: You can opt out of receiving promotional emails from us by following the unsubscribe link in those emails.{"\n"}
              </p>
              <h2 className="privacy__headline2">7. Third-Party Links</h2>
              <p className="privacy__description">Our website may contain links to third-party websites that are not operated by us. If you click on a third-party link, you will be directed to that third party's site. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.</p>

              <h2 className="privacy__headline2">8. Changes to This Privacy Policy</h2>
              <p className="privacy__description">We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Last Updated" date at the top of this Privacy Policy. You are advised to review this Privacy Policy periodically for any changes.</p>

              <h2 className="privacy__headline2">9. Contact Us</h2>
              <p className="privacy__description">
                If you have any questions about this Privacy Policy, please contact us:{"\n"}{"\n"}
                By email: Chiptuning@asferrari.com{"\n"}
                By visiting this page on our website: www.asferrari.com{"\n"}
                By phone: +38269609609{"\n"}
                Your privacy is important to us, and we are committed to ensuring that your personal information is protected and handled with care. Thank you for choosing AsFerrari Chiptuning.{"\n"}
              </p>
            </div>
          </div>
        </section>


      </main >
    </>
  )
}
export default PrivacyPolicy