import React, {useEffect, useState} from 'react';
import AOS from "aos";
import 'swiper/css';
import OffCanvasTuning from "../components/OffCanvasTuning";
import {useTranslation} from "react-i18next";
import {getAbout} from "../api/axios";
import CarBrandsReference from '../components/CarBrandsReference';
import PartnersReference from '../components/PartnersReference';
import ScrollNav from '../components/ScrollNav';
import { LoadingSection, LoadingText } from '../components/LoadingPlaceholder';

const AboutUs = () => {
  const {t, i18n} = useTranslation();
  const [about, setAbout] = useState("");
  const [loading, setLoading] = useState(true);

  const sections = [
    { id: 'intro', label: 'about-us' },
    { id: 'about', label: 'our-story' },
    { id: 'stats', label: 'achievements' },
    { id: 'founder', label: 'founder' },
    { id: 'references', label: 'references' }
  ];

  useEffect(() => {
    document.title = 'About Us | Asferrari ChipTuning';
    AOS.init({
      delay: 100,
      duration: 1000,
    });
    AOS.refresh();

    getAbout()
      .then(value => {
        setAbout(value?.data?.data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error loading about data:', error);
        setLoading(false);
      });
  }, []);

  function onRoadLinkClick(e) {
    document.querySelectorAll(".road__list-link").forEach((value, key) => {
      value.classList.remove("active");
    })
    e.target.classList.add("active")
  }
  return (
    <>
      <OffCanvasTuning />
      <ScrollNav sections={sections} />

      <main>
        <section id="intro" className="intro">
          <img src={"/assets/images/about-bg.png"} alt="background" className="intro__background"/>
          <div data-aos="fade-right" className="container h-100">
            <div className="intro__content">
              {loading ? (
                <>
                  <LoadingText height="50px" width="80%" />
                  <LoadingText height="100px" margin="20px 0" />
                </>
              ) : (
                <>
                  <h1 className="intro__headline">{about?.mainTitle}</h1>
                  <p className="intro__description">
                    {about?.mainDesc}
                  </p>
                </>
              )}
              <a href="#about" className="intro__link">
                <span>{t('read-more')}</span>
                <div className="intro__link-icon">
                  <img src={"/assets/icons/right.svg"} alt="right icon"/>
                </div>
              </a>
            </div>
          </div>
        </section>

        <section id="about" className="about">
          <div data-aos="fade-left" className="container">
            <div className="about__content">
              {loading ? (
                <>
                  <LoadingText height="40px" width="60%" />
                  <LoadingText height="200px" margin="20px 0" />
                </>
              ) : (
                <>
                  <h2 className="about__headline">{about?.title1}</h2>
                  <p className="about__description">
                    {about?.text1}
                  </p>
                </>
              )}
            </div>
          </div>
        </section>

        <section id="stats" className="stats">
          <div data-aos="fade-right" className="container">
            <div className="stats__wrapper">
              <div className="row g-5">
                {loading ? (
                  Array(4).fill(0).map((_, index) => (
                    <div className="col-12 col-md-6 col-lg-3" key={index}>
                      <LoadingSection height="200px" radius="8px" />
                    </div>
                  ))
                ) : (
                  <>
                    <div className="col-12 col-md-6 col-lg-3">
                      <div className="stats__card">
                        <div className="stats__card-icon">
                          <img width="60" height="60" src={"/assets/icons/emerging-industries.png"} alt="COUNTRIES COVERED"/>
                        </div>
                        <span className="stats__card-count">{about?.award1Title}</span>
                        <span className="stats__card-title">{about?.award1Number}</span>
                        <span className="stats__card-subtitle">{about?.award1Text}</span>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-3">
                      <div className="stats__card">
                        <div className="stats__card-icon">
                          <img width="60" height="60" src={"/assets/icons/rise.png"} alt="COSTUMER ASCENSION"/>
                        </div>
                        <span className="stats__card-count">{about?.award2Title}</span>
                        <span className="stats__card-title">{about?.award2Number}</span>
                        <span className="stats__card-subtitle">{about?.award2Text}</span>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-3">
                      <div className="stats__card">
                        <div className="stats__card-icon">
                          <img width="60" height="60" src={"/assets/icons/verified.png"} alt="CERTIFICATE ACHIEVEMENT"/>
                        </div>
                        <span className="stats__card-count">{about?.award3Title}</span>
                        <span className="stats__card-title">{about?.award3Number}</span>
                        <span className="stats__card-subtitle">{about?.award3Text}</span>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-3">
                      <div className="stats__card">
                        <div className="stats__card-icon">
                          <img width="60" height="60" src={"/assets/icons/engine.png"} alt="UPGRADED VEHICLE"/>
                        </div>
                        <span className="stats__card-count">{about?.award4Title}</span>
                        <span className="stats__card-title">{about?.award4Number}</span>
                        <span className="stats__card-subtitle">{about?.award4Text}</span>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </section>

        <section id="founder" className="founder">
          <div data-aos="fade-left" className="container">
            <div className="founder__wrapper">
              <div className="founder__about">
                {loading ? (
                  <>
                    <LoadingText height="40px" width="50%" />
                    <LoadingText height="150px" margin="20px 0" />
                    <LoadingText height="30px" width="200px" margin="20px 0" />
                  </>
                ) : (
                  <>
                    <h3 className="founder__headline">{t('word-founder')}</h3>
                    <p className="founder__description">
                      {about?.wordOfFounder}
                    </p>
                    <div className="founder__signature">
                      <img width="250" height="105" src={"/assets/images/durovic-signature.png"} alt="signature"/>
                    </div>
                    <span className="founder__title">Đorđije Đurović - CEO Asferrari</span>
                  </>
                )}
              </div>
              <div className="founder__img">
                {loading ? (
                  <LoadingSection height="400px" radius="8px" />
                ) : (
                  <>
                    <img src={"/assets/images/founder.png"} alt="AsFerrari tuning"/>
                    <div className="founder__badge">
                      <img src={"/assets/images/badge.png"} width="195" height="144" alt="AsFerrari tuning"/>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </section>

        <section id="references">
          <CarBrandsReference />
          <PartnersReference />
        </section>
      </main>
    </>
  )
}
export default AboutUs